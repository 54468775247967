const p404Dict = {
  NOT_FOUND: "Not Found",
  DESCR_NOT_FOUND: "Sorry, we cannot find that page.",
};

const p500Dict = {
  SERVER_ERROR: "Server error",
  DESCR_SERVER_ERROR: "The server was unable to complete yor request.",
};
export { p404Dict, p500Dict };
