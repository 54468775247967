import Link from "next/link";
import { isInViewport, isMobile } from "../BaseLayout";
import { useState } from "react";
import { useRouter } from "next/router";
import locals, { Lang, defaultLang } from "../../i18n";

function fadeOutAndIn(newValue: string, textbox: HTMLInputElement) {
  textbox.style.transition = "opacity 0.5s";
  textbox.style.opacity = "0";
  setTimeout(() => {
    textbox.innerHTML = newValue;
    textbox.style.opacity = "1";
  }, 500);
}

interface NavbarProps {
  sections: SectionProps;
  className?: string;
}

export interface SectionProps {
  [key: string]: string;
}

const Navbar: React.FC<NavbarProps> = ({ sections, className }) => {
  const router = useRouter();
  const lang: Lang = (router.locale ?? defaultLang) as Lang;
  const locale = locals[lang];

  const sections2Nav: SectionProps = {
    "welcome-section": sections.helloSection,
    "projects-section": sections.projectsSection,
    "competence-section": sections.competencesSection,
    "publications-section": sections.pubblicationsSection,
    "footer-section": sections.footerSection,
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  if (typeof window !== "undefined") {
    let currentSection: string | undefined;
    const textbox = document.querySelector(
      ".paragraph-title"
    ) as HTMLInputElement;

    !isMobile() &&
      window.addEventListener("scroll", () => {
        const visibleSections = Object.keys(sections2Nav).filter(
          (className) => {
            const element = document?.querySelector(`.${className}`);

            return false ? !element : isInViewport(element as Element);
          }
        );
        const newSection = visibleSections[0];
        if (newSection && newSection !== currentSection) {
          currentSection = newSection;
          const value = sections2Nav[currentSection];
          fadeOutAndIn(value, textbox);
        }
      });

    const menu = document.querySelector(".navbarIcon");
    const menuBackground = document.querySelector(".navbarBackground");
    const btns = document.querySelectorAll(".mobile-link");
    btns?.forEach((btn) => {
      btn.addEventListener("click", () => {
        menu?.classList.remove("active");
        menuBackground?.classList.remove("active");
      });
    });
  }

  return (
    <div className="w-full">
      <div className="navbar-container z-[1000] fixed isolate mix-blend-exclusion">
        <nav className="flex fixed top-0 w-full max-w-full text-white justify-between">
          <div className="flex-none w-auto m-5 ml-5 lg:ml-10">
            <Link itemProp="url" href="/">
              <div className="flex justify-end items-end w-16 h-16 border-2 text-4xl pr-1 font-bold font-sans">
                T.
              </div>
            </Link>
          </div>
          <div>
            <div className="hidden lg:flex flex-none w-auto h-auto text-xl p-5 font-medium">
              <a
                href="#publications"
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center"
              >
                <span className="relative p-5 inline-block align-middle  text-center border-lg ease-linear duration-150">
                  {locale.ARTICLES}
                </span>
              </a>
              <a
                href="#footer"
                className="magnetic-btn will-change-transform relative inline-block align-middle text-center"
              >
                <span className="relative p-5 inline-block align-middle text-center border-lg ease-linear duration-150">
                  {locale.CONTACT_ME}
                </span>
              </a>
            </div>
            <div
              className={`navbarIcon w-20 h-20 mt-2 flex lg:hidden ${
                isOpen ? "active" : ""
              }`}
              onClick={toggleMenu}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <g strokeWidth="6.5" strokeLinecap="round">
                  <path
                    d="M72 82.286h28.75"
                    fill="#009100"
                    fillRule="evenodd"
                    stroke="#fff"
                  />
                  <path
                    d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                    fill="none"
                    stroke="#fff"
                  />
                  <path
                    d="M72 125.143h28.75"
                    fill="#009100"
                    fillRule="evenodd"
                    stroke="#fff"
                  />
                  <path
                    d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                    fill="none"
                    stroke="#fff"
                  />
                  <path
                    d="M100.75 82.286h28.75"
                    fill="#009100"
                    fillRule="evenodd"
                    stroke="#fff"
                  />
                  <path
                    d="M100.75 125.143h28.75"
                    fill="#009100"
                    fillRule="evenodd"
                    stroke="#fff"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="hidden lg:inline absolute w-full text-center text-xl p-5 z-[-1]">
            <span className="paragraph-title p-2 m-3 inline-block align-middle"></span>
          </div>
        </nav>
      </div>
      <div
        className={`navbarBackground bg-gray w-full overflow-hidden ${
          isOpen ? "active" : ""
        }`}
      >
        <div className="flex flex-col justify-between h-[80vh] mt-24 text-3xl font-medium text-white">
          <div className="flex flex-col gap-y-3 p-5">
            <a className="mobile-link" itemProp="url" href="#publications">
              {locale.ARTICLES}
            </a>
            <a className="mobile-link" itemProp="url" href="#footer">
              {locale.CONTACT_ME}
            </a>
          </div>
          <div className="text-base md:text-xl divide-y w-full">
            <div className="pl-2 text-lightGray uppercase">
              {locale.SOCIALS}
            </div>
            <div className="flex flex-row justify-between pt-2 p-5">
              <Link
                itemProp="url"
                rel="me"
                href="https://github.com/Tammura"
                target="blank"
              >
                <span>Github</span>
              </Link>
              <Link
                itemProp="url"
                rel="me"
                href="https://www.instagram.com/_tammura/"
                target="blank"
              >
                <span>Instagram</span>
              </Link>
              <Link
                itemProp="url"
                rel="me"
                href="https://www.linkedin.com/in/tammura/"
                target="blank"
              >
                <span>Linkedin</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
