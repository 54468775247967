import Head from "next/head";
import { ReactNode } from "react";
import Footer from "../Footer";
import Navbar, { SectionProps } from "../NavBar";

interface BaseLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  sections: SectionProps;
}

export function isInViewport(element: Element) {
  const rect = element?.getBoundingClientRect();
  return (
    (rect?.top >= 0 || rect?.bottom >= 0) &&
    rect?.top <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export const isMobile = () => {
  if (typeof window !== "undefined")
    return !window?.matchMedia("only screen and (min-width: 1024px)").matches;
};

const BaseLayout: React.FC<BaseLayoutProps> = ({ children, sections }) => {
  if (typeof window !== "undefined") {
    const btns = document?.querySelectorAll(".magnetic-btn");
    btns.forEach((btn) => {
      !isMobile() &&
        btn.addEventListener("mousemove", (event) => {
          const position = btn.getBoundingClientRect();
          const x =
            (event as MouseEvent).pageX - position.left - position.width / 2;
          const y =
            (event as MouseEvent).pageY -
            window.scrollY -
            position.top -
            position.height / 2;
          (btn.children[0] as HTMLElement).style.transform =
            "translate(" + x * 0.15 + "px," + y * 0.3 + "px)";
        });
    });

    btns.forEach((btn) => {
      !isMobile() &&
        btn.addEventListener("mouseout", () => {
          (btn.children[0] as HTMLElement).style.transform =
            "translate(0px, 0px)";
        });
    });

    const cursor = document.querySelector(".cursor");

    !isMobile() &&
      document.addEventListener("mousemove", (event) => {
        cursor?.setAttribute(
          "style",
          `top: ${event.pageY - 10 - document.documentElement.scrollTop}px; 
        left: ${event.pageX - 10}px; opacity:1;`
        );
      });
    !isMobile() &&
      document.addEventListener("click", () => {
        if (cursor && !cursor.classList.contains("link-cursor")) {
          cursor?.classList.add("expand");
          setTimeout(() => {
            cursor?.classList.remove("expand");
          }, 500);
        }
      });
    /* Disable maintaning scroll value on browsers*/
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  }
  return (
    <div className="absolute w-full h-full select-none font-Atikson">
      <div className="relative intro bg-gray">
        <h2 className="logo-header font-bold text-3xl lg:text-5xl">
          <span className="logo">tammura</span>
          <span className="logo">.</span>
        </h2>
        <div className="flex absolute w-full justify-center bottom-10">
          <p className="relative text-white w-fit text-3xl font-bold free-palestine">
            #freepalestine
          </p>
        </div>
      </div>
      <div className="w-full h-full">
        <Head>
          <title>Home page | Tammura</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          ></meta>
        </Head>
        <header>
          <Navbar sections={sections} />
        </header>
        <div
          id="cursor"
          className="hidden lg:inline fixed cursor opacity-0"
        ></div>
        <div className="min-h-screen md:mt-32">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default BaseLayout;
