import { p404Dict, p500Dict } from "./collateral";
import generalDict from "./general";
import footerDict from "./footer";

const enLang = {
  HOME: "Home",

  ...generalDict,
  ...p404Dict,
  ...p500Dict,
  ...footerDict,
};

export default enLang;
