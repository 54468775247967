import React from "react";
import styles from "./ProjectCard.module.css";
import Image, { ImageProps } from "next/image";
import cn from "classnames";
import Link from "next/link";

interface ProjectCardProps {
  project: ProjectProps;
  classNames?: string;
}

export interface ProjectProps {
  name: string;
  description: string;
  image: ImageProps;
  externalLink: string;
  tags: string[];
  colors: { from: string; to: string };
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, classNames }) => {
  const CardBg = {
    background: `linear-gradient(-45deg,${project.colors.from} 0%,${project.colors.to} 80%)`,
  };

  return (
    <div className={cn(styles["background-card"], classNames)} style={CardBg}>
      <div className="flex flex-row space-x-3 w-3/5 mt-3 mb-10 p-2">
        {project.tags.map((el: string) => {
          return (
            <p
              key={el}
              className={cn(
                "flex w-fit h-8 rounded-3xl text-white justify-center items-center px-4",
                styles["technologie-card"]
              )}
            >
              {el}
            </p>
          );
        })}
      </div>
      <div className="flex flex-col justify-start text-left w-full text-white p-2 pl-4 z-[1]">
        <h4 className="font-bold text-4xl lg:text-5xl">{project.name}</h4>
        <div className="link w-fit text-xl">
          {/* #TODO Localizzare scopri di più */}
          <Link
            itemProp="url"
            href={project.externalLink}
            target="_blank"
            rel="noreferrer"
          >
            Scopri di più
          </Link>
        </div>
      </div>
      <div className="absolute w-full h-full">
        <div
          className={cn(
            "absolute rounded-full top-1/3 left-1/2",
            styles["card-background-item"]
          )}
        ></div>
      </div>
      <div className="flex relative justify-end h-full w-full">
        <div
          className={cn("absolute w-48 h-64 bottom-0", styles["card-image"])}
        >
          <Image
            src={project.image.src}
            alt={project.image.alt}
            title={project.name}
            fill={true}
            className="!relative object-cover rounded-t-lg rounded-l-lg"
          ></Image>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
