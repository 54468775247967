import Card from "../Card";

interface PubblicationsProps {
  articles: any;
}

const Publications: React.FC<PubblicationsProps> = ({ articles }) => {
  const totalRows = Math.ceil(articles.length / 2);
  const getCardPosition = (index: number) => {
    index = index + 1;
    let classes = "";
    let rowStart = 0;
    let row = 0;

    row = Math.ceil(index / 2);

    if (index % 2 != 0) {
      rowStart = (row - 1) * 5 + 1;
      classes += "lg:row-span-5 lg:col-start-1";
    } else {
      if (row % 2 != 0) {
        classes += "lg:row-span-6";
        rowStart = (row - 1) * 4 + 1;
      } else {
        classes += "lg:row-span-4";
        rowStart = (row - 1) * 6 + 1;
      }
      classes += " lg:col-start-2";
    }
    classes += ` lg:col-span-1 lg:row-start-${rowStart}`;
    return classes;
  };

  const orderedByDate: any[] = articles.sort((article1: any, article2: any) => {
    const [currday, currmonth, curryear] = article1.date.split("/");
    const [nextday, nextmonth, nextyear] = article2.date.split("/");
    let curr = new Date(+curryear, +currmonth - 1, +currday);
    let next = new Date(+nextyear, +nextmonth - 1, +nextday);
    if (curr > next) {
      return -1;
    } else if (curr < next) {
      return 1;
    }
    return 0;
  });
  let orderedArticles = [];
  for (let article of orderedByDate) {
    if (article.position !== undefined)
      orderedArticles.splice(article.position - 1, 0, article);
    else {
      orderedArticles.push(article);
    }
  }

  return (
    <div
      id="publications"
      className="flex w-full object-contain h-full justify-center my-[10rem] md:my-[20rem] pt-[5rem] publications-section"
    >
      <div
        className="grid grid-rows-publications grid-flow-col grid-cols-publications gap-20 md:gap-10 w-5/6"
        style={{ gridTemplateRows: `repeat(${totalRows * 5}, 10vh)` }}
      >
        {orderedArticles.map((article: any, index: number) => {
          return (
            <Card
              key={index}
              image={article.image}
              title={article.title}
              date={article.date}
              type={article.type}
              url={article.externalLink}
              className={getCardPosition(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Publications;
